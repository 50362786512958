import * as React from 'react';
import './css/Login.css';
import useToken from '../useToken';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Token } from '../types/Token';

interface SendCreateRequest {
    Name: any,
    NumPlayers: any,
    DiscordWebhook: any,
    DiscordMatchWebhook: any,
    DiscordScheduleWebhook: any,
}

async function sendCreateRequest(request: SendCreateRequest, history: any, token: Token) {
    fetch('/League/Create', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }

        history.push('/')
    });
}

type Props = {
    history: any,
}

export default function CreateLeague({ history }: Props) {
    const { token } = useToken();

    const submitLeague = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        await sendCreateRequest({
            Name: data.get('leagueName'),
            NumPlayers: Number(data.get('numPlayers')),
            DiscordWebhook: data.get('discordWebhook') ?? "",
            DiscordMatchWebhook: data.get('discordMatchWebhook') ?? "",
            DiscordScheduleWebhook: data.get('discordScheduleWebhook') ?? "",
        }, history, token);
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <GroupsOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create League
                    </Typography>
                    <Box component="form" onSubmit={submitLeague} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="leagueName"
                            label="League Name"
                            name="leagueName"
                            autoComplete="leagueName"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="numPlayers"
                            label="Number of Players"
                            id="numPlayers"
                            type="number"
                            autoComplete="numPlayers"
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="discordWebhook"
                            label="Discord Webhook for drafting"
                            id="discordWebhook"
                            autoComplete="discordWebhook"
                            helperText="Optional. We send draft messages to your discord channel. You can find your webhook by going to your Discord Server Settings > Integrations."
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="discordMatchWebhook"
                            label="Discord Webhook for showdown replays"
                            id="discordMatchWebhook"
                            autoComplete="discordMatchWebhook"
                            helperText="Optional. This can be the same as your other webhook."
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            name="discordScheduleWebhook"
                            label="Discord Webhook for match scheduling"
                            id="discordScheduleWebhook"
                            autoComplete="discordScheduleWebhook"
                            helperText="Optional. This can be the same as your other webhook."
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Create League
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
}
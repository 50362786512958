import * as React from 'react';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { RequestStatus } from '../../types/RequestStatus';
import { Token } from '../../types/Token';
import { League } from '../../types/League';
import useToken from '../../useToken';
import RequestStatusIndicator from '../RequestStatusIndicator';
import CircularProgress from '@mui/material/CircularProgress';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

async function getLeagueRequest(leagueId: string, token: Token, setLeague: (s: League) => void, setLoading: (b: boolean) => void) {
    fetch('/League/Get/' + leagueId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();
        setLoading(false);

        if (!response.ok) {
            return;
        }

        setLeague(message);
    });
}

interface SendUpdateRequest {
    LeagueId: string,
    Name: any,
    NumPlayers: any,
    DiscordWebhook: any,
    DiscordMatchWebhook: any,
    DiscordScheduleWebhook: any,
}

async function sendUpdateRequest(request: SendUpdateRequest, token: Token, setUpdateRequestStatus: (s: RequestStatus) => void) {
    fetch('/League/Update', {
        method: 'PUT',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        if (!response.ok) {
            setUpdateRequestStatus(RequestStatus.Failed);
            return;
        }

        setUpdateRequestStatus(RequestStatus.Successful);
    });
}

async function startNewSeason(token: Token, leagueId: string, seasonId: string) {
    fetch('/League/StartNewSeason/' + leagueId + '/' + seasonId, {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        if (response.ok) {
            // reload page
            window.location.reload()
        }
    });
}

interface Props {
    leagueId: string,
    seasonId?: string,
}

export default function LeagueSettingsTab({ leagueId, seasonId }: Props) {
    const { token } = useToken();
    const [loading, setLoading] = useState<boolean>(true);
    const [league, setLeague] = useState<League>();
    const [updateRequestStatus, setUpdateRequestStatus] = useState<RequestStatus>(RequestStatus.None);
    const [startNewSeasonDialogOpen, setStartNewSeasonDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        if (token && loading && leagueId) {
            getLeagueRequest(leagueId, token, setLeague, setLoading);
        }
    }, [token, loading, leagueId]);

    const submitLeagueUpdate = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        await sendUpdateRequest({
            LeagueId: leagueId,
            Name: data.get('name'),
            NumPlayers: Number(data.get('numPlayers')),
            DiscordWebhook: data.get('discordWebhook') ?? "",
            DiscordMatchWebhook: data.get('discordMatchWebhook') ?? "",
            DiscordScheduleWebhook: data.get('discordScheduleWebhook') ?? "",
        }, token, setUpdateRequestStatus);
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{ mt: 1, height: '650px' }}>
                {loading && (
                    <Box>
                        <CircularProgress />
                    </Box>
                )}
                {league && !loading && (
                    <Box>
                        <Typography variant="h6">
                            League Settings
                        </Typography>
                        <Typography variant="caption" gutterBottom>
                            Set league name, team count, and more
                        </Typography>
                        <Box component="form" onSubmit={submitLeagueUpdate}>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="name"
                                label="League Name"
                                name="name"
                                defaultValue={league.Name}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="numPlayers"
                                label="Number of teams"
                                name="numPlayers"
                                type="number"
                                defaultValue={league.NumPlayers}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="discordWebhook"
                                label="Discord Webhook for drafting"
                                name="discordWebhook"
                                defaultValue={league.DiscordWebhook}
                                helperText="Sends a message to your discord channel when a pick is made. You can find your webhook by going to your Discord Server Settings > Integrations."
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="discordMatchWebhook"
                                label="Discord Webhook for showdown replays"
                                name="discordMatchWebhook"
                                defaultValue={league.DiscordMatchWebhook}
                                helperText="Sends a message to your discord channel when a replay is uploaded. You can find your webhook by going to your Discord Server Settings > Integrations."
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                id="discordScheduleWebhook"
                                label="Discord Webhook for match scheduling"
                                name="discordScheduleWebhook"
                                defaultValue={league.DiscordScheduleWebhook}
                                helperText="Sends a message to your discord channel when a match is scheduled. You can find your webhook by going to your Discord Server Settings > Integrations."
                            />
                            <Box sx={{ display: 'flex', mt: 3, mb: 2 }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Update League
                                </Button>
                                <Box sx={{ mt: '6px', ml: '6px' }} >
                                    <RequestStatusIndicator status={updateRequestStatus} />
                                </Box>
                            </Box>
                            <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => setStartNewSeasonDialogOpen(true)}>
                                Start new season
                            </Button>
                            <Dialog
                                open={startNewSeasonDialogOpen}
                                onClose={() => setStartNewSeasonDialogOpen(false)}
                            >
                                <DialogTitle>
                                    Are you sure?
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        This will end the current season and let you start a new draft. This cannot be undone.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => setStartNewSeasonDialogOpen(false)}>Disagree</Button>
                                    <Button onClick={() => startNewSeason(token, leagueId, seasonId ?? "")} autoFocus>
                                        Agree
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </Box>
                )}
            </Box>            
        </>
    );
}


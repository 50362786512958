import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Pokemon } from '../types/Pokemon';
import { Type } from '../types/PokemonType';
import { Button, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { PendingPick } from '../types/PendingPick';

function getPokemonNameFromPieces(name: string, variation: string) {
    if (variation) {
        return name + " (" + variation + ")";
    }
    else {
        return name;
    }
}

function getPokemonName(pokemon: Pokemon) {
    return getPokemonNameFromPieces(pokemon.Name, pokemon.Variation);
}

type PokemonRow = {
    id: string,
    pokemon: string,
    tier: number,
    type1: Type,
    type2?: Type,
    total: number,
    hp: number,
    attack: number,
    defense: number,
    spatk: number,
    spdef: number,
    speed: number,
    uniqueId: number,
}

function buildRowsForPokemon(pokemon?: Pokemon[]) {
    const rows: PokemonRow[] = [];

    if (pokemon) {
        pokemon.map(mon => rows.push({
            id: mon.UniqueId.toString(),
            pokemon: getPokemonName(mon),
            tier: mon.Tier,
            type1: mon.Type1,
            type2: mon.Type2,
            total: mon.Total,
            hp: mon.Hp,
            attack: mon.Attack,
            defense: mon.Defense,
            spatk: mon.SpAtk,
            spdef: mon.SpDef,
            speed: mon.Speed,
            uniqueId: mon.UniqueId,
        }));
    }

    return rows;
}

type Props = {
    pokemon?: Pokemon[],
    pickSet: Set<number>,
    selectPokemon?: (pokemon?: Pokemon) => void,
    isLeagueOwner?: boolean,
    forcePickClicked?: () => void,
    clearPickClicked?: () => void,
    showPickButton: boolean,
    costEnabled: boolean,
    moneyLeft?: number,
    openTierList?: () => void,
    isSmallScreen: boolean,
    pendingPicks?: PendingPick[],
}

export default function PokemonStatsSearchTable({ pokemon, pickSet, selectPokemon, isLeagueOwner, forcePickClicked, clearPickClicked, showPickButton, costEnabled, moneyLeft, openTierList, isSmallScreen, pendingPicks }: Props) {
    const originalRows = pokemon?.filter(row => !pickSet.has(row.UniqueId));
    const [typeFilter, setTypeFilter] = useState('');
    const [searchText, setSearchText] = useState('');

    const typeFilterChanged = (event: SelectChangeEvent) => {
        setTypeFilter(event.target.value);
    }

    const requestSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredRows = originalRows?.filter((row) => {
        return (getPokemonName(row).toLowerCase().includes(searchText.toLowerCase()) && (!typeFilter || typeFilter === row.Type1 || typeFilter === row.Type2));
    });

    const handleButtonClick = (rowData: PokemonRow) => {
        const pokemon = originalRows?.find(p => p.UniqueId === rowData.uniqueId);

        if (selectPokemon) {
            selectPokemon(pokemon);
        }        
    };

    const columns = [];

    if (showPickButton) {
        columns.push({
            field: 'pick',
            headerName: 'Pick',
            width: 80,
            disableColumnMenu: true,
            sortable: false,
            renderCell: (params: { row: PokemonRow; }) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleButtonClick(params.row as PokemonRow)}
                >
                    <AddIcon />
                </Button>
            ),
        });
    }

    const addlColumns = [
        {
            field: 'pokemon',
            headerName: 'Pokemon',
            width: 150,
        },
        {
            field: 'tier',
            headerName: costEnabled ? 'Price' : 'Tier',
            width: 60,
        },
        {
            field: 'type1',
            headerName: 'Type 1',
            sortable: false,
            width: 75,
        },
        {
            field: 'type2',
            headerName: 'Type 2',
            sortable: false,
            width: 75,
        },
        {
            field: 'total',
            headerName: 'Tot',
            type: 'number',
            width: 10,
        },
        {
            field: 'hp',
            headerName: 'HP',
            type: 'number',
            width: 10,
        },
        {
            field: 'attack',
            headerName: 'Att',
            type: 'number',
            width: 10,
        },
        {
            field: 'defense',
            headerName: 'Def',
            type: 'number',
            width: 10,
        },
        {
            field: 'spatk',
            headerName: 'SpA',
            type: 'number',
            width: 10,
        },
        {
            field: 'spdef',
            headerName: 'SpD',
            type: 'number',
            width: 10,
        },
        {
            field: 'speed',
            headerName: 'Spe',
            type: 'number',
            width: 10,
        },
    ];

    columns.push(...addlColumns);

    const rowsToUse = buildRowsForPokemon(filteredRows);
    let tableHeight = costEnabled ? '75%' : '80%';
    if (isSmallScreen) {
        tableHeight = '30vh';
    }

    return (
        <>
            <Paper elevation={4} sx={{ p: 2, overflow: 'hidden', height: '50vh' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {costEnabled && (
                            <Typography>
                                Money left: {moneyLeft}
                            </Typography>
                        )}                        
                        
                    </Box>
                    {(pendingPicks && pendingPicks.length > 0) && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={clearPickClicked}
                            sx={{ mt: 1, ml: 1 }}
                        >
                            Clear queued picks
                        </Button>                     
                    )}
                    {(!isSmallScreen && openTierList) && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={openTierList}
                            sx={{ mt: 1, ml: 1, float: 'right' }}
                        >
                            View Tier List
                        </Button>
                    )}
                </Box>
                <Box sx={{ mt: 2 }}>
                    <TextField
                        id="outlined-basic"
                        value={searchText}
                        label="Find Pokemon"
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={requestSearch} />
                    <FormControl sx={{ minWidth: 70, maxWidth: 74 }}>
                        <InputLabel id="type-search-label">Type</InputLabel>
                        <Select
                            labelId="type-search-label"
                            id="type-select"
                            value={typeFilter}
                            label="typeFilter"
                            onChange={typeFilterChanged}
                            autoWidth
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Bug">Bug</MenuItem>
                            <MenuItem value="Dark">Dark</MenuItem>
                            <MenuItem value="Dragon">Dragon</MenuItem>
                            <MenuItem value="Electric">Electric</MenuItem>
                            <MenuItem value="Fairy">Fairy</MenuItem>
                            <MenuItem value="Fighting">Fighting</MenuItem>
                            <MenuItem value="Fire">Fire</MenuItem>
                            <MenuItem value="Flying">Flying</MenuItem>
                            <MenuItem value="Ghost">Ghost</MenuItem>
                            <MenuItem value="Grass">Grass</MenuItem>
                            <MenuItem value="Ground">Ground</MenuItem>
                            <MenuItem value="Ice">Ice</MenuItem>
                            <MenuItem value="Normal">Normal</MenuItem>
                            <MenuItem value="Poison">Poison</MenuItem>
                            <MenuItem value="Psychic">Psychic</MenuItem>
                            <MenuItem value="Rock">Rock</MenuItem>
                            <MenuItem value="Steel">Steel</MenuItem>
                            <MenuItem value="Water">Water</MenuItem>
                        </Select>
                    </FormControl>
                    {(forcePickClicked && isLeagueOwner) && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={forcePickClicked}
                            sx={{ mt: 1, ml: 1 }}
                        >
                            Force pick
                        </Button>
                    )}                    
                </Box>                
                <Box sx={{ height: tableHeight, marginTop: 1 }}>
                    <DataGrid
                        rows={rowsToUse}
                        columns={columns}
                        pageSize={100}
                        disableSelectionOnClick
                    />
                </Box>
            </Paper>
        </>
    );
}
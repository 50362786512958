import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Alert, Box, Button, CardActions, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material';
import { HistoricalMatch } from '../../../../types/HistoricalMatch';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Tooltip from '@mui/material/Tooltip';
import { Token } from '../../../../types/Token';
import { useRef, useState } from 'react';
import useToken from '../../../../useToken';
import { formatMatchDate, Schedule } from './Matchups';
import MatchTab from '../../MatchTab';
import { getActiveTeamName } from './ScheduleCreator';
import { Team } from '../../../../types/Team';

export const DEFAULT_TIME = "0001-01-01T00:00:00";

interface SetMatchTimeRequest {
    ScheduleId: string
    timestamp: string;
    IncludeGif: boolean;
    IsReschedule: boolean;
    FlavorText: string;
}

async function setMatchTime(request: SetMatchTimeRequest, token: Token) {
    fetch('/Match/Time', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }
    });
}

function stringToColor(string?: string) {
    if (!string) {
        return '#000000';
    }

    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
}

interface MatchupCardProps {
    leagueId: string;
    seasonId: string;
    leagueOwner: boolean;
    schedule: Schedule;
    team1: Team | undefined;
    team2: Team | undefined;
    historicalMatch: HistoricalMatch | undefined;
    userTeamId: number;
    reload: () => void;
}

function getCurrentDateTimeString() {
    const now = new Date();
    return now.toISOString().slice(0, 16); // Format for 'datetime-local'
};

export default function MatchupCard({ leagueId, seasonId, leagueOwner, schedule, team1, team2, historicalMatch, userTeamId, reload }: MatchupCardProps) {
    const [scheduleState, setScheduleState] = useState<Schedule>(schedule);
    const [visibleExtraSection, setVisibleExtraSection] = useState<string>("");
    const [datetimeValue, setDatetimeValue] = useState<string>(scheduleState?.ScheduledTime ? scheduleState.ScheduledTime : "");
    const [includeGif, setIncludeGif] = useState<boolean>(false);
    const flavorText = useRef<HTMLInputElement>(null);
    const { token } = useToken();

    const handleSubmitDatetime = () => {
        if (!datetimeValue) {
			alert("Please enter a valid date and time");
			return;
        }

        if (datetimeValue === scheduleState?.ScheduledTime) {
            alert("That time is already saved for this match");
            return;
        }

		const request: SetMatchTimeRequest = {
            ScheduleId: scheduleState?.Id || "",
            timestamp: datetimeValue,
            IncludeGif: includeGif,
            IsReschedule: scheduleState?.ScheduledTime != DEFAULT_TIME,
            FlavorText: flavorText.current?.value || ""
		};
        setMatchTime(request, token);
        setScheduleState({ ...scheduleState, ScheduledTime: datetimeValue });
        setVisibleExtraSection("");
    }

    const handleExtraSectionButtonClick = (section: string) => {
        if (visibleExtraSection === section) {
			setVisibleExtraSection("");
		} else {
			setVisibleExtraSection(section);
		}
    }

    const team1Name = getActiveTeamName(team1);
    const team2Name = getActiveTeamName(team2);
    
    let winnerId = 0;
    if (historicalMatch) {        
        if (historicalMatch.PlayerOne === historicalMatch.Winner) {
            winnerId = historicalMatch.PlayerOneTeamId;
        }
        else if (historicalMatch.PlayerTwo === historicalMatch.Winner) {
            winnerId = historicalMatch.PlayerTwoTeamId;
        }
        else {
            // If the match has no players or url, then the winning team id is set in the winner field
            winnerId = Number(historicalMatch.Winner);
        }
    }    

    let formattedTime = "";
    if (scheduleState?.ScheduledTime != DEFAULT_TIME && scheduleState?.ScheduledTime != undefined) {
        formattedTime = formatMatchDate(scheduleState.ScheduledTime);
    }

    const userCanTakeAction = leagueOwner || userTeamId === team1?.Id || userTeamId === team2?.Id;

    return (
        <Card sx={{ marginBottom: '16px', borderRadius: '8px' }}>
            <CardContent sx={{ padding: '16px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', width: '40%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Avatar sx={{ bgcolor: stringToColor(team1Name) }}>{team1Name?.charAt(0)}</Avatar>
                            {historicalMatch && winnerId === team1?.Id && (
                                <EmojiEventsIcon sx={{ ml: 1, color: 'gold' }} />
                            )}                            
                        </Box>                        
                        <Typography variant="h6" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{team1Name}</Typography>                        
                    </Box>
                    <Box sx={{
                        flexGrow: 1,  // This allows the Box to take up available space
                        display: 'flex',
                        justifyContent: 'center', // Center content horizontally
                        width: '10%'
                    }}>
                        <Avatar sx={{ height: 36, width: 36 }}>vs</Avatar>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', width: '40%', textAlign: 'right' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {historicalMatch && winnerId === team2?.Id && (
                                <EmojiEventsIcon sx={{ mr: 1, color: 'gold' }} />
                            )}             
                            <Avatar sx={{ bgcolor: stringToColor(team2Name) }}>{team2Name?.charAt(0)}</Avatar>     
                        </Box>
                        <Box>
                            <Typography variant="h6">{team2Name}</Typography>
                        </Box>                        
                    </Box>
                </Box>
            </CardContent>
            <CardActions sx={{ bgcolor: '#2a3245' }}>
                {userCanTakeAction && (
                    <>
                        <Tooltip title="Set match time" placement="top">
                            <Button variant="outlined" color="primary" onClick={() => handleExtraSectionButtonClick("date")} sx={{ minWidth: "115px", whiteSpace: "nowrap" }}>
                                Schedule
                                <CalendarMonthIcon fontSize="small" sx={{ ml: 1 }} />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add showdown replay" placement="top">
                            <Button variant="outlined" color="primary" onClick={() => handleExtraSectionButtonClick("replay")} sx={{ minWidth: "115px", whiteSpace: "nowrap" }}>
                                {historicalMatch?.ReplayUrl ? "Edit replay" : "Add replay"}
                            </Button>
                        </Tooltip>  
                    </>
                )}                
                {historicalMatch?.ReplayUrl && (
                    <Tooltip title="Watch replay" placement="top">
                        <IconButton
                            color="primary"
                            onClick={() => window.open(historicalMatch.ReplayUrl, '_blank')} // Open in new tab
                        >
                            <OndemandVideoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
                {formattedTime.length > 0 && (
                    <Box sx={{ textAlign: 'right', width: '100%' }}>
                        <Typography variant="body1">{formattedTime}</Typography>
                    </Box>                    
                )}
            </CardActions>
            {visibleExtraSection === "date" && (
                <>
                    <Alert severity="info" sx={{ m: 1 }}>Dates are not timezone-specific. This is the time that everyone will see</Alert>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', m: 1 }}>                            
                            <input
                                aria-label="Date and time"
                                type="datetime-local"
                                id="dateTimeInput"
                                value={datetimeValue === DEFAULT_TIME ? getCurrentDateTimeString() : datetimeValue}
                                onChange={(e) => setDatetimeValue(e.target.value)}
                            />
                            <TextField
                                autoFocus
                                inputProps={{ maxLength: 1000 }}
                                margin="dense"
                                id="feedback"
                                label="Flavor text (max 1000 chars)"
                                fullWidth
                                variant="standard"
                                inputRef={flavorText}
                                defaultValue=""
                                multiline
                                rows={4}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeGif}
                                        onChange={(e) => setIncludeGif(e.target.checked)}
                                    />
                                }
                                label="random gif"
                            />
                        </Box>
                        <Box sx={{ ml: 3 }}>
                            <Button variant="outlined" color="primary" onClick={() => handleSubmitDatetime()}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </>                
            )}
            {visibleExtraSection === "replay" && team1 && team2 && (
                <MatchTab teams={[team1, team2]} leagueId={leagueId} seasonId={seasonId} scheduleId={scheduleState.Id} leagueOwner={leagueOwner} callback={reload} />
            )}
        </Card>
    );
}

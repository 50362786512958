import React, { useEffect, useRef } from 'react';
import Chart, { ChartData, ChartDataset, ChartOptions } from 'chart.js/auto';
import { ShowdownPlayer } from '../Stats/MatchStatsTable';


interface Props {
    teams: ShowdownPlayer[];
}

const DamageBarChart: React.FC<Props> = ({ teams }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart | null>(null); // Ref to store chart instance

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Generate a random ID for the canvas
        const canvasId = Math.random().toString(36).substring(2, 15);
        canvas.id = canvasId; 

        const labels: string[] = [];
        const datasets: ChartDataset<'bar', number[]>[] = [];

        teams.forEach((team, teamIndex) => {
            team.Pokemon.forEach((pokemon) => {
                if (pokemon.Pokemon != null) {
                    labels.push(pokemon.Pokemon.Name);
                }
                else {
                    labels.push(pokemon.Name);
                }
            });

            datasets.push({
                label: `Damage`,
                data: team.Pokemon.map((p) => p.DamageDealt),
                backgroundColor: `rgba(255, 99, 132, 1)`,
                borderColor: `rgba(255, 99, 132, 1)`,
                borderWidth: 1,
            });

            datasets.push({
                label: `Hazard Damage`,
                data: team.Pokemon.map((p) => p.HazardDamageDealt),
                backgroundColor: `rgba(255, 206, 86, 1)`,
                borderColor: `rgba(255, 206, 86, 1)`,
                borderWidth: 1,
            });

            datasets.push({
                label: `Status Damage`,
                data: team.Pokemon.map((p) => p.StatusDamageDealt),
                backgroundColor: `rgba(75, 192, 192, 1)`,
                borderColor: `rgba(75, 192, 192, 1)`,
                borderWidth: 1,
            });

            datasets.push({
                label: `Weather Damage`,
                data: team.Pokemon.map((p) => p.WeatherDamageDealt),
                backgroundColor: `rgba(54, 162, 235, 1)`,
                borderColor: `rgba(54, 162, 235, 1)`,
                borderWidth: 1,
            });
        });

        const data: ChartData<'bar', number[]> = {
            labels,
            datasets,
        };

        const options: ChartOptions<'bar'> = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Damage',
                        color: 'white'
                    },
                    ticks: {
                        color: 'white'
                    }
                },
                x: {
                    title: {
                        display: true,
                        text: 'Pokemon',
                        color: 'white'
                    },
                    ticks: {
                        color: 'white'
                    }
                },
            },
            plugins: {
                legend: {
                    labels: {
                        color: 'white',
                    }
                },
                title: {
                    display: true,
                    text: 'Damage Breakdown',
                    color: 'white'
                },
            },
        };

        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
        });

        return () => {
            if (chartInstance.current) {
				chartInstance.current.destroy();
			}
        }
    }, [teams]);

    // Helper function to generate somewhat distinct colors
    const getRandomColor = (seed: number): string => {
        const h = (seed * 60) % 360;
        const s = 70 + Math.floor(Math.random() * 50);
        const l = 50 + Math.floor(Math.random() * 30);
        return `${h}, ${s}, ${l}`;
    };

    return <div style={{ height: '50vh' }}><canvas ref={canvasRef} style={{ height: '400px' }} /></div>;
};

export default DamageBarChart;
import { useHistory } from "react-router-dom";
import useToken from "../../useToken";
import { Box, Button, Container, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import discordLogo from '../../assets/discord-logo-white.svg';
import draftImage from '../../assets/draft-image.PNG';
import BallotIcon from '@mui/icons-material/Ballot';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeAppBar from "./HomeAppBar";
import './styles.css'; 


export default function PublicHome() {
    const history = useHistory();
    const { token } = useToken();
    
    if (token) {
        history.push("/home");
    }
    
    return (
        <Box>
            <CssBaseline />
            <HomeAppBar />
            <Paper sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4">
                        Manage your Pokemon Draft League
                    </Typography>
                    <Typography variant="h6" marginTop={2} color={"neutral.main"}>
                        This free tool will help you manage your league before, during, and after the draft.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/home')}
                        sx={{ fontSize: '16px', mt: 2 }}
                    >
                        Get Started
                    </Button>
                </Box>
            </Paper>
            <Container maxWidth={false} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
                <Box marginTop={2} marginLeft={2}>
                    <div className="scrolling-image-container">
                        <div className="scrolling-image-wrapper">
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                            <img src={draftImage} alt="Logo" className="scrolling-image" />
                        </div>
                    </div>
                </Box>
                <Box className="content-container" sx={{ flexGrow: 1, minHeight: '400px' }}>
                    <Grid container justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            minHeight={150}
                            key={'1'}
                            m={2}
                            sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                            <Paper sx={{ textAlign: 'center', p: 1, width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <BallotIcon color="primary" fontSize="large" />
                                    <Typography ml={0.5}>
                                        Draft
                                    </Typography>
                                </Box>
                                <Typography>
                                    Customize which Pokemon can be drafted and make your own tier list, or use a tier list created by the community. 
                                    See your team's stats and weaknesses so you can make the best pick.
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            minHeight={150}
                            key={'2'}
                            m={2}
                            sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                            <Paper sx={{ textAlign: 'center', p: 1, width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CatchingPokemonIcon color="primary" fontSize="large" />
                                    <Typography ml={0.5}>
                                        Prepare for matches
                                    </Typography>
                                </Box>

                                <Typography>Prepare for your matches after the draft. We show you your opponent's stats, weaknesses, and speed tiers in the app. No need for bespoke spreadsheets.</Typography>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            minHeight={150}
                            key={'2'}
                            m={2}
                            sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                            <Paper sx={{ textAlign: 'center', p: 1, width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <EventAvailableIcon color="primary" fontSize="large" />
                                    <Typography ml={0.5}>
                                        Scheduling
                                    </Typography>
                                </Box>

                                <Typography>Create a random schedule or import your own. Easily keep track of upcoming matches.</Typography>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            minHeight={150}
                            key={'2'}
                            m={2}
                            sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                            <Paper sx={{ textAlign: 'center', p: 1, width: '100%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <AssessmentIcon color="primary" fontSize="large" />
                                    <Typography ml={0.5}>
                                        Match Stats
                                    </Typography>
                                </Box>

                                <Typography>Upload your pokemon showdown replays for automatic stat and win tracking.</Typography>
                            </Paper>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={3.5}
                            minHeight={150}
                            key={'3'}
                            m={2}
                            sx={{ display: 'flex', alignItems: 'stretch' }}
                        >
                            <Paper sx={{ textAlign: 'center', p: 1, width: '100%' }}>
                                <Box m={0.75}>
                                    <img src={discordLogo} alt="Logo" width="125px" />
                                </Box>
                                <Typography>Integrates with your discord channel. You'll receive draft updates and reminders without needing to download another one-off messaging app.</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>            
        </Box>
    );
}
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { PokemonTeam } from '../types/PokemonTeam';
import getPokemonName from '../util/pokeFormatting';
import PokemonImage from './PokemonImage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
	team: PokemonTeam;
}

const BESPOKE_POKEMON_MAPPINGS: { [key: string]: string } = {
	deoxys: "deoxys-normal",
	basculin: "basculin-red-striped",
	shaymin: "shaymin-land",
	"tauros-paldea-combat": "tauros-paldea-combat-breed",
	"tauros-paldea-blaze": "tauros-paldea-blaze-breed",
	"tauros-paldea-aqua": "tauros-paldea-aqua-breed",
	meowstic: "meowstic-male",
	"meowstic-f": "meowstic-female",
	lycanroc: "lycanroc-midday",
	mimikyu: "mimikyu-disguised",
	minior: "minior-red-meteor",
	morpeko: "morpeko-full-belly",
	toxtricity: "toxtricity-amped",
	basculegion: "basculegion-male",
	"basculegion-f": "basculegion-female",
	indeedee: "indeedee-male",
	"indeedee-f": "indeedee-female",
	"indeedee-both-genders": "indeedee-male",
	eiscue: "eiscue-ice",
	squawkabilly: "squawkabilly-green-plumage",
	dudunsparce: "dudunsparce-two-segment",
	"oinkologne-both-genders": "oinkologne-male",
	"oinkologne": "oinkologne-male",
	"oinkologne-f": "oinkologne-female",
	"oricorio-all-variations": "oricorio-baile",
	"pikachu-partner": "pikachu",
	wormadam: "wormadam-plant",
	giratina: "giratina-altered",
	"burmy-sandy": "burmy",
	"burmy-trash": "burmy",
	darmanitan: "darmanitan-standard",
	"darmanitan-galar": "darmanitan-galar-standard",
	tornadus: "tornadus-incarnate",
	thundurus: "thundurus-incarnate",
	landorus: "landorus-incarnate",
	enamorus: "enamorus-incarnate",
	keldeo: "keldeo-resolute",
	meloetta: "meloetta-pirouette",
	aegislash: "aegislash-blade",
	pumpkaboo: "pumpkaboo-small",
	gourgeist: "gourgeist-small",
	oricorio: "oricorio-baile",
	zygarde: "zygarde-complete",
	"zygarde-10%": "zygarde-10",
	"oricorio-pa'u": "oricorio-pau",
	"necrozma-dawn-wings": "necrozma-dawn",
	"necrozma-dusk-mane": "necrozma-dusk",
	wishiwashi: "wishiwashi-school",
	"type:-null": "type-null",
	"minior-meteor": "minior-orange-meteor",
	urshifu: "urshifu-rapid-strike",
	maushold: "maushold-family-of-three",
	"maushold-four": "maushold-family-of-four",
	palafin: "palafin-hero",
	tatsugiri: "tatsugiri-droopy",
	"squawkabilly-yellow": "squawkabilly-yellow-plumage",
	"squawkabilly-white": "squawkabilly-white-plumage",
	"squawkabilly-blue": "squawkabilly-blue-plumage",
	"ogerpon-cornerstone": "ogerpon-cornerstone-mask",
	"ogerpon-hearthflame": "ogerpon-hearthflame-mask",
	"ogerpon-wellspring": "ogerpon-wellspring-mask",
}

const MOVES_THAT_SWITCH_OUT = [
	"baton-pass",
	"chilly-reception",
	"u-turn",
	"volt-switch",
	"flip-turn",
	"parting-shot",
	"teleport",
	"shed-tail",
];

const MOVES_THAT_TAUNT = [
	"taunt"
];

const MOVES_THAT_MANIP_ITEM = [
	"bestow",
	"bug-bite",
	"corrosive-gas",
	"covet",
	"embargo",
	"fling",
	"g-max-replenish",
	"incinerate",
	"knock-off",
	"magic-room",
	"natural-gift",
	"pluck",
	"recycle",
	"stuff-cheeks",
	"switcheroo",
	"teatime",
	"thief",
	"trick",
];

const MOVES_THAT_SET_SCREEN = [
	"aurora-veil",
	"baddy-bad",
	"g-max-resonance",
	"glitzy-glow",
	"light-screen",
	"relect",
];

const MOVES_THAT_REMOVE_SCREEN = [
	"brick-break",
	"defog",
	"g-max-wind-rage",
	"psychic-fangs",
	"raging-bull",
	"shadow-shed",
];

const MOVES_THAT_HAZARD_SET = [
	"ceaseless-edge",
	"g-max-steelsurge",
	"g-max-stonesurge",
	"spikes",
	"stealth-rock",
	"sticky-web",
	"stone-axe",
	"toxic-spikes",
];

const MOVES_THAT_HAZARD_REMOVE = [
	"defog",
	"g-max-wind-rage",
	"mortal-spin",
	"rapid-spin",
	"tidy-up",
];

const MOVES_THAT_PRIORITY = [
	"accelerock",
	"aqua-jet",
	"baby-doll-eyes",
	"bullet-punch",
	"grassy-glide",
	"ice-shard",
	"jet-punch",
	"mach-punch",
	"quick-attack",
	"shadow-sneak",
	"sucker-punch",
	"vacuum-wave",
	"water-shuriken",
	"ally-switch",
	"extreme-speed",
	"feint",
	"first-impression",
	"follow-me",
	"rage-powder",
	"fake-out",
	"kings-shield",
	"obstruct",
	"silk-trap",
	"helping-hand",
];

const MOVES_THAT_REDIRECT = [
	"follow-me",
	"rage-powder",
];

const MOVES_SPEED_CONTROL = [
	// These are common speed control moves but far from all. Not including all as the list would be bloated and hard to parse
	"electroweb",
	"icy-wind",
	"tailwind",	
	"trick-room",
];

const MOVE_LISTS = {
	switchOut: MOVES_THAT_SWITCH_OUT,
	taunt: MOVES_THAT_TAUNT,
	manipItem: MOVES_THAT_MANIP_ITEM,
	setScreen: MOVES_THAT_SET_SCREEN,
	removeScreen: MOVES_THAT_REMOVE_SCREEN,
	hazardSet: MOVES_THAT_HAZARD_SET,
	hazardRemove: MOVES_THAT_HAZARD_REMOVE,
	priority: MOVES_THAT_PRIORITY,
	redirect: MOVES_THAT_REDIRECT,
	speedControl: MOVES_SPEED_CONTROL,
};

const MOVE_LIST_NAMES = {
	switchOut: "Switch Out",
	taunt: "Taunt",
	manipItem: "Manipulate Item",
	setScreen: "Set Screen",
	removeScreen: "Remove Screen",
	hazardSet: "Hazard Set",
	hazardRemove: "Hazard Remove",
	priority: "Priority",
	redirect: "Redirection",
	speedControl: "Speed Control",
};

// Define a type for expanded
type ExpandedState = Record<keyof typeof MOVE_LISTS, boolean>; 

type PokemonFromApi = {
	name: string;
	moves: { move: { name: string } }[];
}

function findMoves(pokemon: PokemonFromApi) {
	const result: { move: string; list: keyof typeof MOVE_LISTS }[] = [];

	pokemon.moves.forEach((move) => {
		for (const listName in MOVE_LISTS) {
			if (MOVE_LISTS[listName as keyof typeof MOVE_LISTS].includes(move.move.name)) {
				result.push({ move: move.move.name, list: listName as keyof typeof MOVE_LISTS });
			}
		}
	});

	return result;
}

function getRowsForList(team: PokemonTeam, listName: string, moves: { pokemonId: any; move: string }[]) {
	let rows: JSX.Element[] = [];
	let pokemonMovesMap: { [key: string]: string[] } = {};
	if (!moves) {
		return rows;
	}

	// Create a mapping of Pokemon to their moves
	moves.forEach((move) => {
		const mon = team.Pokemon.find((mon) => mon.UniqueId === move.pokemonId);
		if (mon) {
			const pokemonName = getPokemonName(mon);
			if (!pokemonMovesMap[pokemonName]) {
				pokemonMovesMap[pokemonName] = [move.move];
			} else {
				pokemonMovesMap[pokemonName].push(move.move);
			}
		}
	});

	// Iterate over the mapping and create a row for each Pokemon
	Object.entries(pokemonMovesMap).forEach(([pokemonName, moves]) => {
		const mon = team.Pokemon.find((mon) => getPokemonName(mon) === pokemonName);
		rows.push(
			<TableRow key={pokemonName + listName}>
				<TableCell>
					{mon && (<PokemonImage pokemon={mon} />)}
					{pokemonName}
				</TableCell>
				<TableCell>{moves.join(', ')}</TableCell>
			</TableRow>
		);
	});

	return rows;
}

export default function PokemonMoveCategories({ team }: Props) {
	const [aggregatedData, setAggregatedData] = useState<Record<string, {
        pokemonId: number; move: string; list: keyof typeof MOVE_LISTS 
	}[]>>({});
	const [loading, setLoading] = useState(true);
	const [expanded, setExpanded] = useState<ExpandedState>({
		switchOut: false,
		taunt: false,
		manipItem: false,
		setScreen: false,
		hazardSet: false,
		hazardRemove: false,
		removeScreen: false,
		priority: false,
		redirect: false,
		speedControl: false,
	});

	useMemo(() => {
		setAggregatedData({});
		setLoading(true);

		Promise.all(team.Pokemon.map(async (pokemon, index) => {
			let lowercaseName = pokemon.Name.toLowerCase().replace(" ", "-").replace(".", "");
			if (pokemon.Variation) {
				lowercaseName = lowercaseName + "-" + pokemon.Variation.toLowerCase().replace(" ", "-").replace(".", "");
			}
			if (BESPOKE_POKEMON_MAPPINGS[lowercaseName]) {
				lowercaseName = BESPOKE_POKEMON_MAPPINGS[lowercaseName];
			}

			const response = await fetch(`https://pokeapi.co/api/v2/pokemon/${lowercaseName}`);
			if (response.ok) {
				const data: PokemonFromApi = await response.json();
				const moves = findMoves(data);
				return moves.map(move => ({ ...move, pokemonId: pokemon.UniqueId }));
			}
			else {
				console.log("Failed to fetch pokemon data for " + lowercaseName);
			}
			return [];
		})).then(allMoves => {
			const newAggregatedData = allMoves.reduce((acc: any, moves, index) => {
				moves.forEach(move => {
					if (!acc[move.list]) {
						acc[move.list] = [];
					}
					acc[move.list].push(move);
				});
				return acc;
			}, {});
			setAggregatedData(newAggregatedData);
			setLoading(false);
		});
	}, [team]);
	
	return (
		<div>
			<Paper elevation={4} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
				<Box>
					<Typography variant="h6">
						Important Moves
					</Typography>
					{loading && <CircularProgress />}
					{!loading && (
						<>
							<Button onClick={() => {
								const newExpanded: ExpandedState = { ...expanded };
								if (Object.values(expanded).some(value => !value)) {
									// If some are collapsed, expand all
									Object.keys(MOVE_LISTS).forEach((key) => {
										const typedKey = key as keyof typeof MOVE_LISTS; // Tell TS that key is a valid key of MOVE_LISTS
										newExpanded[typedKey] = true;
									});
								} else {
									// If all are expanded, collapse all
									Object.keys(MOVE_LISTS).forEach((key) => {
										const typedKey = key as keyof typeof MOVE_LISTS;
										newExpanded[typedKey] = false;
									});
								}
								setExpanded(newExpanded);
							}}>
								{Object.values(expanded).some(value => !value) ? 'Expand All' : 'Collapse All'}
							</Button>
							{/* Iterate over MOVE_LISTS array to maintain order */ }
							{Object.keys(MOVE_LISTS).map(listName => {
								return (
									<Accordion key={listName} expanded={expanded[listName as keyof typeof MOVE_LISTS]} onChange={() => setExpanded({ ...expanded, [listName]: !expanded[listName as keyof typeof MOVE_LISTS] })}>
										<AccordionSummary
											expandIcon={<ExpandMoreIcon />}
											aria-controls={`${listName}-content`}
											id={`${listName}-header`}
										>
											<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
												<Typography variant="h6">
													{MOVE_LIST_NAMES[listName as keyof typeof MOVE_LIST_NAMES]}
												</Typography>
												<Tooltip
													title={
														<React.Fragment>
															<Typography variant="body2" color="inherit">
																Moves checked for {MOVE_LIST_NAMES[listName as keyof typeof MOVE_LIST_NAMES]}:
															</Typography>
															<ul>
																{MOVE_LISTS[listName as keyof typeof MOVE_LISTS].map((move) => (
																	<li key={move}>{move}</li>
																))}
															</ul>
														</React.Fragment>
													}
													placement="right"
													arrow
												>

													<InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 0.5 }} />
												</Tooltip>
											</Box>											
										</AccordionSummary>
										<AccordionDetails>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow key={'header' + listName}>
															<TableCell sx={{ width: "50%" }} key={'pokemon' + listName}>Pokemon</TableCell>
															<TableCell key={'move' + listName}>Move</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{getRowsForList(team, listName, aggregatedData[listName])}
													</TableBody>
												</Table>
											</TableContainer>
										</AccordionDetails>
									</Accordion>
								);
							})}
						</>
					)}
				</Box>
			</Paper>
		</div>
	);
}
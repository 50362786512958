import * as React from 'react';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Pokemon } from '../../types/Pokemon';
import { PokemonTeam } from '../../types/PokemonTeam';
import PokemonImage from '../PokemonImage';

async function getMatchStats(matchId: number, token: Token, setData: (r: GetMatchStatsResponse) => void) {
	fetch('/Match/Stats/' + matchId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			alert("Unable to load at this time. Try again later");
			return;
		}

		setData(message);
	});
}

export interface GetMatchStatsResponse {
	Stats: ShowdownPlayer[];
	Winner: string;
}

export interface PokemonBattleStats {
	Pokemon: Pokemon;
	Kills: number;
	Fainted: boolean;
	DamageDealt: number;
	HazardDamageDealt: number;
	StatusDamageDealt: number;
	WeatherDamageDealt: number;
	DamageHealed: number;
	DamageTaken: number;
	Name: string;
}

export interface ShowdownPlayer {
    Username: string;
	TeamId: number;
	Pokemon: PokemonBattleStats[]; 
}

interface MatchStatsTableProps {
	matchId: number;
	teams: PokemonTeam[];
}


export default function MatchStatsTable(props: MatchStatsTableProps) {
	const [data, setData] = useState<GetMatchStatsResponse | null>(null);

	const { token } = useToken();

	useEffect(() => {
		if (token) {
			getMatchStats(props.matchId, token, setData);
		}
	}, [token]);

	if (!data) {
		return null;
	}

	const teamNameForTeamId = (teamId: number) => {
		const team = props.teams.find(t => t.TeamId === teamId);
		if (!team) {
			return "Unknown";
		}

		const displayName = team.TeamName && team.TeamName.length > 0 ? team.TeamName : team.OwnerDisplayName;
		return displayName;
	};

	return (
		<Box>
			{data.Stats.map((team, teamIndex) => (
				<Box key={"expanded-stats-" + teamIndex}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell colSpan={9} align="center">
									{teamNameForTeamId(team.TeamId)}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Pokemon</TableCell>
								<TableCell>Kills</TableCell>
								<TableCell>Fainted</TableCell>
								<TableCell>Damage Taken</TableCell>
								<TableCell>Damage Dealt</TableCell>
								<TableCell>Hazard Damage Dealt</TableCell>
								<TableCell>Status Damage Dealt</TableCell>
								<TableCell>Weather Damage Dealt</TableCell>
								<TableCell>Damage Healed</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{team.Pokemon.map((pokemon, pokemonIndex) => (
								<TableRow key={pokemonIndex}>									
									<TableCell>
										{pokemon.Pokemon && (
											<Box>
												<PokemonImage pokemon={pokemon.Pokemon} />
												{pokemon.Pokemon.Name}
											</Box>											
										)}										
									</TableCell>
									<TableCell>{pokemon.Kills}</TableCell>
									<TableCell>{pokemon.Fainted ? "Yes" : "No"}</TableCell>
									<TableCell>{pokemon.DamageTaken}</TableCell>
									<TableCell>{pokemon.DamageDealt}</TableCell>
									<TableCell>{pokemon.HazardDamageDealt}</TableCell>
									<TableCell>{pokemon.StatusDamageDealt}</TableCell>
									<TableCell>{pokemon.WeatherDamageDealt}</TableCell>
									<TableCell>{pokemon.DamageHealed}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			))}			
		</Box>		
	);
}

import { Alert, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { PokemonTeam } from '../../../../types/PokemonTeam';
import { Team } from '../../../../types/Team';
import { DEFAULT_TIME } from './MatchupCard';
import { Schedule } from './Matchups';


interface ScheduleCreatorProps {
    pendingSchedule: Schedule[],
    setPendingSchedule: (matches: Schedule[]) => void,
    teams: Team[],
    saveScheduleUpdates: () => void,
}

export function getActiveTeamName(team?: Team) {
    return team?.Name && team?.Name.length > 0 ? team?.Name : team?.OwnerDisplayName;
}

export function getTeamName(team?: PokemonTeam) {
    return team?.TeamName && team?.TeamName.length > 0 ? team?.TeamName : team?.OwnerDisplayName;
}

export default function ScheduleCreator({ pendingSchedule, setPendingSchedule, teams, saveScheduleUpdates }: ScheduleCreatorProps) {
    const [numberOfWeeks, setNumberOfWeeks] = React.useState(0);

    const handleGenerateClick = () => {
        const newSchedule: Schedule[] = [];
        const teamIds = teams.map((team: Team) => team.Id);
        const teamCount = teamIds.length;
        const weeksToMake = Math.min(numberOfWeeks, 50);

        // Track which teams have played each other
        const playedMatches: { [key: string]: boolean } = {};

        // Function to check if a match is a rematch
        const isRematch = (team1Id: number, team2Id: number) => {
            const matchKey = team1Id < team2Id ? `${team1Id}-${team2Id}` : `${team2Id}-${team1Id}`;
            return playedMatches[matchKey];
        };

        // Function to check if all teams have played each other at least once
        const allTeamsPlayedOnce = () => {
            for (let i = 0; i < teamCount; i++) {
                for (let j = i + 1; j < teamCount; j++) {
                    if (!isRematch(teamIds[i], teamIds[j])) {
                        return false;
                    }
                }
            }
            return true;
        };

        for (let i = 0; i < weeksToMake; i++) {
            // Shuffle teamIds for randomization
            for (let j = teamCount - 1; j > 0; j--) {
                const k = Math.floor(Math.random() * (j + 1));
                [teamIds[j], teamIds[k]] = [teamIds[k], teamIds[j]];
            }

            for (let j = 0; j < teamCount; j += 2) {
                // Ensure we don't go out of bounds if teamCount is odd
                if (j + 1 < teamCount) {
                    const team1Id = teamIds[j];
                    const team2Id = teamIds[j + 1];

                    // Check for rematch only if all teams have played once
                    if (allTeamsPlayedOnce() && !isRematch(team1Id, team2Id)) {
                        // Try to find a non-rematch pair by swapping with the next team
                        if (j + 3 < teamCount && !isRematch(team1Id, teamIds[j + 3])) {
                            [teamIds[j + 1], teamIds[j + 3]] = [teamIds[j + 3], teamIds[j + 1]];
                        } else if (j + 2 < teamCount && !isRematch(team2Id, teamIds[j + 2])) {
                            [teamIds[j], teamIds[j + 2]] = [teamIds[j + 2], teamIds[j]];
                        }
                        // If no swap is possible, proceed with the rematch
                    }

                    const newMatchup = {
                        Id: "0",
                        TeamOneId: team1Id,
                        TeamTwoId: team2Id,
                        Week: i + 1,
                        ScheduledTime: DEFAULT_TIME
                    };

                    newSchedule.push(newMatchup);

                    // Mark the match as played
                    const matchKey = team1Id < team2Id ? `${team1Id}-${team2Id}` : `${team2Id}-${team1Id}`;
                    playedMatches[matchKey] = true;
                }
            }
        }

        setPendingSchedule(newSchedule);
    };

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="h5" sx={{ mt: 2 }}>
                Create Schedule
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">                
                <TextField
                    label="Number of Weeks"
                    type="number"
                    value={numberOfWeeks}
                    onChange={(e) => setNumberOfWeeks(parseInt(e.target.value, 10))}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: 50,
                    }}
                    margin="normal"
                />
                <Box sx={{ ml: 1 }}>
                    <Button variant="contained" onClick={handleGenerateClick} sx={{ mt: 2 }}>
                        Generate
                    </Button>
                </Box>
                {pendingSchedule.length > 0 && (
                    <Box sx={{ ml: 1 }}>
                        <Button variant="contained" onClick={saveScheduleUpdates} sx={{ mt: 2 }}>
                            Save
                        </Button>
                    </Box>  
                )}                              
            </Box>            
            {pendingSchedule.length > 0 && (
                <>
                    <Alert severity="info" sx={{ mt: 1, mb: 1 }}>You will be able to edit this schedule after it's saved.</Alert> 
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Week</TableCell>
                                    <TableCell>Team 1</TableCell>
                                    <TableCell>Team 2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pendingSchedule.map((schedule: Schedule, index: number) => (
                                    <TableRow
                                        key={`pending-schedule-game-${index}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {schedule.Week}
                                        </TableCell>
                                        <TableCell>
                                            {getActiveTeamName(teams.find((team) => team.Id === schedule.TeamOneId))}
                                        </TableCell>
                                        <TableCell>
                                            {getActiveTeamName(teams.find((team) => team.Id === schedule.TeamTwoId))}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>                
            )}
        </Box>
    );
}
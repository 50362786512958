import { Box } from '@mui/material';
import React from 'react';
import { Pokemon } from '../../types/Pokemon';
import { PokemonBattleStats } from '../Stats/MatchStatsTable';

interface PokemonGifProps {
	pokemon: PokemonBattleStats;
}

function getPokemonGifUrl(pokemon: Pokemon) {
	const lowercasePokemon = pokemon.Name.toLowerCase().replace(' ', '-');
	const imageUrl = (pokemon.Id <= 905)
		? `https://projectpokemon.org/images/normal-sprite/${lowercasePokemon}.gif`
		: `https://img.pokemondb.net/sprites/scarlet-violet/normal/${lowercasePokemon}.png`;
	return imageUrl;
}

function getPokemonGifUrlFromName(name: string) {
	const lowercasePokemon = name.toLowerCase().replace(' ', '-');
	const imageUrl = `https://img.pokemondb.net/sprites/scarlet-violet/normal/${lowercasePokemon}.png`;
	return imageUrl;
}

export default function PokemonGif({ pokemon }: PokemonGifProps) {
	const imageUrl = pokemon.Pokemon != null ? getPokemonGifUrl(pokemon.Pokemon) : getPokemonGifUrlFromName(pokemon.Name);

	return (
		<Box sx={{ height: "100%", width: "137px", display: "flex", alignItems: "center", justifyContent: "center" }}>
			<img src={imageUrl} alt="" style={{ maxWidth: "100%", maxHeight: "100%" }} />
		</Box>
	);
}

import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import useToken from '../../useToken';
import Login from '../../components/Login'
import DraftTierTable from './DraftTierTable';
import PokemonStatsSearchTable from '../PokemonStatsSearchTable';
import { useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Token } from '../../types/Token';
import { Pokemon } from '../../types/Pokemon';
import { PendingPick } from '../../types/PendingPick';
import { DraftPick } from '../../types/DraftPick';
import { SelectablePokemon } from '../PokemonManager/types';
import TeamTab from '../HomeTabs/TeamTab';
import { PokemonTeam } from '../../types/PokemonTeam';
import DraftBoard from './DraftBoard/DraftBoard';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { Divider } from './Divider';
import SubmitPickDialog from './SubmitPickDialog';
import ForcePickDialog from './ForcePickDialog';
import GenericDialog from './GenericDialog';
import { HubConnectionBuilder } from '@microsoft/signalr';

/*
 * All pokemon sprites.
 * if id < 906: https://img.pokemondb.net/sprites/sword-shield/icon/{lowercase name}.png
 * if id >= 906: https://img.pokemondb.net/sprites/scarlet-violet/icon/{lowercase name}.png
 */

interface LoadDataResponse {
    DraftPicks: DraftPick[],
    Pokemon: Pokemon[],
    SelectablePokemonJson: string,
    PendingPicks: PendingPick[],
    ExternalLeagueId: string,
    ExternalSeasonId: string,
    IsLeagueOwner: boolean,
    TeamId: number,
    CostEnabled: boolean,
    Money: number,
}

interface SocketUpdates {
    DraftPicks: DraftPick[],
}

// TODO: Move this into a hook
async function loadData(token: Token, draftId: string, setResponse: (s: LoadDataResponse) => void, setLoading: (l: boolean) => void) {
    fetch('/Draft/Load/' + draftId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();

        if (!response.ok) {
            alert("AHHHHHHHHHH");
            return;
        }

        setResponse(message);
        setLoading(false);
    });
}

interface SubmitPickRequest {
    LeagueId: string,
    SeasonId: string,
    Pick: number,
    PrimaryPick: Pokemon | null,
    BackupPick: Pokemon | null,
}

// TODO: Move this into a hook
async function submitPick(request: SubmitPickRequest, token: Token) {
    fetch('/Draft/MakePick', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }

        alert("Pick submitted");
    });
}

interface ClearPickRequest {
    LeagueId: string,
    SeasonId: string,
}

// TODO: Move this into a hook
async function submitClearPick(request: ClearPickRequest, token: Token) {
    fetch('/Draft/ClearPick', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }

        alert("Your queued picks (if any) have been canceled");
    });
}

interface SubmitForcePickRequest {
    LeagueId: string,
    SeasonId: string,
    Pick: number,
    Pokemon: Pokemon,
}

// TODO: Move this into a hook
async function submitForcePick(request: SubmitForcePickRequest, token: Token) {
    fetch('/Draft/ForcePick', {
        method: 'POST',
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(request)
    }).then(async response => {
        const message = await response.text();
        if (!response.ok) {
            alert(message);
            return;
        }

        alert("Pick submitted");
    });
}

// TODO: I can remove this and use the existing hook
function getPokemonNameFromPieces(name: string, variation: string) {
    if (variation) {
        return name + " (" + variation + ")";
    }
    else {
        return name;
    }
}

// TODO: I can remove this and use the existing hook
function getPokemonName(pokemon: Pokemon) {
    return getPokemonNameFromPieces(pokemon.Name, pokemon.Variation);
}

// Extracts which pokemon are draftable from the API response
function getDraftablePokemon(allPokemon: Pokemon[], selectablePokemonJson: string) {
    const draftablePokemon: Map<number,Pokemon> = new Map<number,Pokemon>();

    // Construct a map of pokemonId to SelectablePokemon
    let selectablePokemonSettings = JSON.parse(selectablePokemonJson) as SelectablePokemon[];
    let pokemonSettingsMap = new Map<number, SelectablePokemon>();
    selectablePokemonSettings.forEach(ps => pokemonSettingsMap.set(ps.uniqueId, ps));

    // Construct rows for each pokemon
    allPokemon.forEach(p => {
        let pokemonSettings = pokemonSettingsMap.get(p.UniqueId);
        if (!pokemonSettings) {
            return;
        }

        if (pokemonSettings.selected) {
            p.Tier = pokemonSettings.cost ?? 0;
            draftablePokemon.set(p.UniqueId, p);
        }
    })

    return draftablePokemon;
}

type DraftParams = {
    draftIdStr: string,
}

export default function Draft({ history }: any) {
    const { token, setToken } = useToken();
    let { draftIdStr } = useParams<DraftParams>();
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<LoadDataResponse>();
    const [pokemonTeams] = useState<PokemonTeam[]>([]);
    const [pickSet] = useState<Set<number>>(new Set<number>());
    const [draftablePokemon, setDraftablePokemon] = useState<Map<number, Pokemon>>(new Map<number, Pokemon>());
    const [ws, setWs] = useState<WebSocket>();
    const [draftPicks, setDraftPicks] = useState<DraftPick[]>([]);
    const [pendingPicks, setPendingPicks] = useState<PendingPick[]>([]);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [height, setHeight] = useState(50);
    const [selectedPokemon, setSelectedPokemon] = useState<Pokemon>();
    const [submitPickDialogOpen, setSubmitPickDialogOpen] = useState<boolean>(false);
    const [submitForcePickDialogOpen, setSubmitForcePickDialogOpen] = useState<boolean>(false);
    const [tierDialogOpen, setTierDialogOpen] = useState<boolean>(false);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    

    // Reroute bad url
    if (!draftIdStr) {
        history.push('/');
    }

    useEffect(() => {
        // call initial load apis here
        if (token && loading && draftIdStr) {
            loadData(token, draftIdStr, setData, setLoading);
        }
    }, [token, loading, draftIdStr]);

    // SignalR connection handling
    useEffect(() => {
        if (!data || !data.ExternalSeasonId) {
			return;
		}

        const connection = new HubConnectionBuilder()
            .withUrl("/draftUpdaterHub")
            .build();

        connection.on('ReceivePick', (data) => {
            let socketUpdate: SocketUpdates = JSON.parse(data);
            console.log("received signalr update");
            setDraftPicks(socketUpdate.DraftPicks);
        });

        connection.start().then(() => {
            connection.invoke("JoinDraft", data.ExternalSeasonId);
        }).catch(err => console.error(err));

        return () => {
            connection.invoke("LeaveDraft", draftIdStr).catch(err => console.error(err));
            connection.stop();
        };
    }, [data]);

    useEffect(() => {
        if (data && draftablePokemon.size === 0) {
            setDraftPicks(data.DraftPicks.sort((x, y) => { return x.Pick - y.Pick }));
            setDraftablePokemon(getDraftablePokemon(data.Pokemon, data.SelectablePokemonJson));
        }
        if (data?.PendingPicks) {
			setPendingPicks(data.PendingPicks);
		}
    }, [data, draftablePokemon]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    function handleDrag(e: { clientY: number; }) {
        // Calculate new height based on current mouse position
        let newHeight = e.clientY / window.innerHeight * 100;

        // Update height state
        setHeight(newHeight);
    }

    // Process picks make thus far
    let moneyLeft = data?.Money ?? 0;
    if (data && draftPicks && draftablePokemon) {
        pokemonTeams.splice(0);

        for (let i = 0; i < draftPicks.length; i++) {
            let teamsPicks = pokemonTeams.find(pt => pt.TeamId === draftPicks[i].TeamId);
            if (!teamsPicks) {
                pokemonTeams.push({ TeamName: "", OwnerDisplayName: draftPicks[i].OwnerDisplayName, TeamId: draftPicks[i].TeamId, Pokemon: [], OwnerShowdownUsername: "" });
                teamsPicks = pokemonTeams[pokemonTeams.length - 1];
            }

            let pokemon = draftablePokemon.get(draftPicks[i].PokemonUniqueId);
            
            if (pokemon !== undefined) {
                pickSet.add(pokemon.UniqueId);
                teamsPicks.Pokemon.push(pokemon);

                // If it's our pick
                if (draftPicks[i].TeamId === data.TeamId) {
                    moneyLeft -= pokemon.Tier;

                    // Clear the first pending pick if we have picked it.
                    if (pokemon.UniqueId === pendingPicks[0]?.PrimaryPick?.UniqueId) {
                        setPendingPicks(pendingPicks.slice(1));
					}
                }   
            }
        }
    }

    let myPickNumbersLeft = data?.DraftPicks.filter(dp => dp.TeamId === data.TeamId && dp.PokemonUniqueId === 0).map(dp => dp.Pick) ?? [];

    const sendPick = async (pickNum: number, primaryPick: Pokemon | null, backupPick: Pokemon | null) => {
        setSubmitPickDialogOpen(false);

        if (!primaryPick && !backupPick) {
            alert("No pokemon selected");
            return;
        }

        if (data?.CostEnabled) {
            // Get the sum of all pending picks before this one, taking the max of the primary and backup
            let moneyQueuedUp = 0;
            for (let i = 0; i < pendingPicks.length; i++) {
                const pp = pendingPicks[i];

                if (pp.Pick >= pickNum) {
                    continue;
                }

				moneyQueuedUp += Math.max(pp.PrimaryPick?.Tier ?? 0, pp.BackupPick?.Tier ?? 0);
			}

            const moneyToSpend = Math.max(moneyLeft - moneyQueuedUp, 0);

            if (primaryPick && primaryPick.Tier > moneyToSpend || (backupPick && backupPick.Tier > moneyToSpend)) {
                alert("You don't have enough money left to queue this pokemon. This takes into account the max that you could spend with your queued picks.");
                return;
            }
        }

        const pick = {
            LeagueId: data?.ExternalLeagueId ?? "",
            SeasonId: data?.ExternalSeasonId ?? "",
            Pick: pickNum,
            PrimaryPick: primaryPick,
            BackupPick: backupPick
        }

        // Does pendingpicks already contain this pickNum? If so, replace it. Otherwise, add it.
        const existingPick = pendingPicks.find(pp => pp.Pick === pickNum);
        if (existingPick) {
            existingPick.PrimaryPick = primaryPick;
            existingPick.BackupPick = backupPick;
        } else {
            const newPick = {
                Pick: pickNum,
                PrimaryPick: primaryPick,
                BackupPick: backupPick
            }

            setPendingPicks([...pendingPicks, newPick]);
        }

        await submitPick(pick, token);
    }

    const sendForcePick = async (pickNum: number, pokemon: Pokemon) => {
        setSubmitForcePickDialogOpen(false);

        if (!pokemon || pickNum < 1) {
            alert("No pokemon selected to force pick");
            return;
        }

        await submitForcePick({
            LeagueId: data?.ExternalLeagueId ?? "",
            SeasonId: data?.ExternalSeasonId ?? "",
            Pokemon: pokemon,
            Pick: pickNum
        }, token);
    }

    const sendClearPick = async () => {
        setPendingPicks([]);
        await submitClearPick({
            LeagueId: data?.ExternalLeagueId ?? "",
            SeasonId: data?.ExternalSeasonId ?? "",
        }, token);
    }

    const selectPokemon = (pokemon?: Pokemon) => {
        if (pokemon === undefined) {
            return;
        }

        setSelectedPokemon(pokemon);
        setSubmitPickDialogOpen(true);
    };

    const memoSearch = useMemo(() => <PokemonStatsSearchTable pokemon={Array.from(draftablePokemon.values())} pickSet={pickSet} selectPokemon={selectPokemon} isLeagueOwner={data?.IsLeagueOwner} costEnabled={data?.CostEnabled ?? false} moneyLeft={moneyLeft} forcePickClicked={() => setSubmitForcePickDialogOpen(true)} clearPickClicked={() => sendClearPick()} showPickButton={!!data?.TeamId} openTierList={() => setTierDialogOpen(true)} isSmallScreen={isSmallScreen} pendingPicks={pendingPicks} />, [draftablePokemon, pickSet, isSmallScreen, pendingPicks]);
    const memoTier = useMemo(() => <DraftTierTable pokemon={Array.from(draftablePokemon.values())} pickSet={pickSet} costEnabled={data?.CostEnabled ?? false} />, [draftablePokemon, pickSet, draftPicks]);
    const memoTeamTab = useMemo(() => <TeamTab teams={pokemonTeams} defaultTeamId={data?.TeamId} seasonId={data?.ExternalSeasonId} isLeagueOwner={false} showTier={true} showFilter={false} />, [data?.ExternalSeasonId, data?.TeamId]);

    if (!token) {
        return <Login setToken={setToken} />
    }

    return (
        <>
            <CssBaseline />
            <Box sx={{
                flexGrow: 1,
                overflow: 'auto',
            }}>
                {loading && (
                    <Box sx={{ position: "fixed", top: "50%", left: "50%" }}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
                        <Box sx={{ height: `${height}%`, flex: 1, overflow: 'auto' }}>
                            <Box>
                                <IconButton color="primary" aria-label="Go back" onClick={() => history.push('/home/' + data?.ExternalLeagueId)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Box>
                            <DraftBoard draftPicks={draftPicks} pokemonMap={draftablePokemon} />
                        </Box>
                        <SubmitPickDialog isOpen={submitPickDialogOpen} submitPick={sendPick} selectedPokemon={selectedPokemon} pendingPicks={pendingPicks} onDialogClose={() => setSubmitPickDialogOpen(false)} pickNumbers={myPickNumbersLeft} />
                        {data?.IsLeagueOwner && (
                            <ForcePickDialog pokemon={data.Pokemon} submitPick={sendForcePick} isOpen={submitForcePickDialogOpen} onDialogClose={() => setSubmitForcePickDialogOpen(false)} />
                        )}
                        {isSmallScreen && (
                            <Box sx={{ width: '100%', overflow: 'auto' }}>
                                <Tabs value={activeTab} onChange={handleTabChange} aria-label="memo tabs">
                                    <Tab label="Pick" />
                                    <Tab label="Team" />
                                    <Tab label="Tier list" />
                                </Tabs>
                                {activeTab === 0 && memoSearch}
                                {activeTab === 1 && memoTeamTab}
                                {activeTab === 2 && memoTier}
                            </Box>
                        )}
                        {!isSmallScreen && (
                            <>
                                <GenericDialog isOpen={tierDialogOpen} onDialogClose={() => setTierDialogOpen(false)} content={memoTier} />
                                <Divider onDrag={handleDrag} />
                                <Box sx={{ display: 'flex', height: `${100 - height}%`, flex: 1, flexDirection: 'row' }}>
                                    {isSmallScreen && (
                                        <>
                                            {memoSearch}
                                        </>
                                    )}
                                    {!isSmallScreen && (
                                        <Box sx={{ minWidth: '850px' }}>
                                            {memoSearch}
                                        </Box>
                                    )}                                    
                                    <Paper elevation={4} sx={{ width: '100%', overflow: 'auto' }}>
                                        <Box sx={{ width: '100%', overflow: 'auto' }}>
                                            {memoTeamTab}                                                                              
                                        </Box>
                                    </Paper>  
                                </Box>
                            </>                            
                        )}                        
                    </Box>
                )}                
            </Box>
        </>
    );
}

import * as React from 'react';
import Box from '@mui/material/Box';
import { PokemonTeam } from '../../types/PokemonTeam';
import { CircularProgress, Paper, Typography } from '@mui/material';
import { Token } from '../../types/Token';
import useToken from '../../useToken';
import { useEffect, useMemo, useState } from 'react';
import PokemonStatsSearchTable from '../PokemonStatsSearchTable';
import { GetDraftablePokemonSettingsResponse, SelectablePokemon } from '../PokemonManager/types';
import { Pokemon } from '../../types/Pokemon';
import StatLeaders from '../Stats/StatLeaders';

async function getPokemonList(leagueId: string, token: Token, setData: (r: GetDraftablePokemonSettingsResponse) => void) {
	fetch('/League/DraftablePokemon/' + leagueId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			alert("Unable to load at this time. Try again later");
			return;
		}

		setData(message);
	});
}

interface PokemonTabProps {
	teams: PokemonTeam[],
	leagueId: string,
	seasonId: string,
}

export default function PokemonTab({ teams, leagueId, seasonId }: PokemonTabProps) {
	const [data, setData] = useState<GetDraftablePokemonSettingsResponse>();
	const [loading, setLoading] = useState<boolean>(true);
	const [pokemon, setPokemon] = useState<Pokemon[]>([]);

	const { token } = useToken();

	// Load data from server
	useEffect(() => {
		if (loading && !data) {
			getPokemonList(leagueId, token, setData);
		}
	}, [loading, leagueId, token, data, setData]);

	// Turn Pokemon array into a map for building the table
	useEffect(() => {
		if (loading && data?.Pokemon) {
			let allPokemonMap = new Map<number, Pokemon>();
			data.Pokemon.forEach(p => allPokemonMap.set(p.UniqueId, p));

			// Construct a map of pokemonId to SelectablePokemon
			let selectablePokemonSettings = data?.SelectablePokemonJson ? JSON.parse(data.SelectablePokemonJson) as SelectablePokemon[] : [];
			let pokemonSettingsMap = new Map<number, SelectablePokemon>();
			selectablePokemonSettings.forEach(ps => pokemonSettingsMap.set(ps.uniqueId, ps));

			// Remove pokemon that are not draftable
			let draftablePokemon: Pokemon[] = [];
			allPokemonMap.forEach(p => {
				if (pokemonSettingsMap.has(p.UniqueId) && pokemonSettingsMap.get(p.UniqueId)?.selected) {
					p.Tier = pokemonSettingsMap.get(p.UniqueId)?.cost ?? 0;
					draftablePokemon.push(p);
				}
			});

			// Set the pokemon map			
			setPokemon(draftablePokemon);
			setLoading(false);
		}
	}, [loading, data]);

	// Get list of unique pokemon ids from the teams prop
	const pickedPokemonIds = useMemo(() => {
		let uniquePokemonIds = new Set<number>();
		teams.forEach(t => {
			t.Pokemon.forEach(p => uniquePokemonIds.add(p.UniqueId));
		});
		return uniquePokemonIds;
	}, [teams]);

	const memoSearch = useMemo(() =>
		<PokemonStatsSearchTable
			pokemon={pokemon}
			pickSet={pickedPokemonIds}
			costEnabled={data?.CostEnabled ?? false}
			moneyLeft={0}
			showPickButton={false}
			isSmallScreen={false} />,
		[pokemon, pickedPokemonIds]);

	return (
		<>
			{(!teams || teams.length === 0) && (
				<Box sx={{ m: 2, p: 2 }}>
					Free agents can be viewed here after the draft is over.
				</Box>
			)}
			{teams.length > 0 && (
				<Box>
					<StatLeaders seasonId={seasonId} />
					<Paper elevation={3} sx={{ p: 2, m: 2, display: "flex", flexDirection: "column" }}>
						<Box>
							{loading && (
								<Box sx={{ display: 'flex' }}>
									<CircularProgress />
								</Box>
							)}
							{!loading && (
								<Box>
									<Typography component="h2" variant="h6" gutterBottom>
										Free Agents
									</Typography>
									<Typography variant="body2" color="neutral" sx={{ mb: 1 }}>
										These pokemon are available and can be added to your team by a league admin
									</Typography>
									{memoSearch}
								</Box>
							)}
						</Box>
					</Paper>
				</Box>
			)}
		</>
	);
}
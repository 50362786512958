import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { PokemonTeam } from '../../types/PokemonTeam';
import PokemonTeamWrapper from '../PokemonTeamWrapper';
import { Token } from '../../types/Token';
import { HistoricalMatch } from '../../types/HistoricalMatch';
import useToken from '../../useToken';
import { CircularProgress } from '@mui/material';
import MatchHistory from './LeagueTab/components/MatchHistory';
import { useMediaQuery } from '@mui/material';
import { SelectablePokemon } from '../PokemonManager/types';

async function getMatchHistoryRequest(seasonId: string, playerTeamId: number, token: Token, setMatchHistory: any) {
    fetch('/Match/PlayerHistory/' + seasonId + "/" + playerTeamId, {
        method: 'GET',
        headers: new Headers({
            'Authorization': 'Bearer ' + token
        })
    }).then(async response => {
        const message = await response.json();

        if (!response.ok) {
            return;
        }

        setMatchHistory(message);
    });
}

function getMenuItemsForTeams(teams: PokemonTeam[]) {
    const options = [];
    const nativeOptions = [];

    const sortedTeams = [...teams].sort((a, b) => {
        const teamNameA = a.TeamName ? `${a.TeamName} (${a.OwnerDisplayName})` : a.OwnerDisplayName;
        const teamNameB = b.TeamName ? `${b.TeamName} (${b.OwnerDisplayName})` : b.OwnerDisplayName;

        if (teamNameA < teamNameB) {
            return -1;
        }
        if (teamNameA > teamNameB) {
            return 1;
        }
        return 0;
    });

    for (let i = 0; i < sortedTeams.length; i++) {
        let teamName = (sortedTeams[i].TeamName) ? `${sortedTeams[i].TeamName} (${sortedTeams[i].OwnerDisplayName})` : sortedTeams[i].OwnerDisplayName;
        options.push(<MenuItem value={sortedTeams[i].TeamId}>{teamName}</MenuItem>);
        nativeOptions.push(<option value={sortedTeams[i].TeamId}>{teamName}</option>);
    }

    return {
        options: options,
        nativeOptions: nativeOptions
    };
}

// Extracts which pokemon are draftable from the API response
function hydrateDraftablePokemon(pokemonTeams: PokemonTeam[], selectablePokemonJson: string) {
    // Construct a map of pokemonId to SelectablePokemon
    let selectablePokemonSettings = JSON.parse(selectablePokemonJson) as SelectablePokemon[];
    let pokemonSettingsMap = new Map<number, SelectablePokemon>();
    selectablePokemonSettings.forEach(ps => pokemonSettingsMap.set(ps.uniqueId, ps));

    // Construct rows for each pokemon
    let allPokemon = pokemonTeams.flatMap(t => t.Pokemon);
    allPokemon.forEach(p => {
        let pokemonSettings = pokemonSettingsMap.get(p.UniqueId);
        if (!pokemonSettings) {
            return;
        }

        if (pokemonSettings.selected) {
            p.Tier = pokemonSettings.cost ?? 0;
        }
    })
}

type Props = {
    teams: PokemonTeam[],
    seasonId?: string,
    isLeagueOwner: boolean,
    showTier: boolean,
    showFilter: boolean,
    defaultTeamId?: number,
    selectablePokemonJson?: string,
}

export default function TeamTab({ teams, seasonId, isLeagueOwner, showTier, showFilter, defaultTeamId, selectablePokemonJson }: Props) {
    const [selectedTeam, setSelectedTeam] = useState<string>("");
    const [matchHistory, setMatchHistory] = useState<HistoricalMatch[]>([]);
    const [loadingMatchHistory, setLoadingMatchHistory] = useState<boolean>(true);
    const { token } = useToken();
    const isMobile = useMediaQuery('(max-width: 600px)');

    useEffect(() => {
        if (!teams || teams.length === 0) {
            return;
        }

        if (selectablePokemonJson) {
			hydrateDraftablePokemon(teams, selectablePokemonJson);
		}

        if (defaultTeamId) {
            setSelectedTeam(defaultTeamId.toString());
        } else {
            setSelectedTeam(teams[0]?.TeamId?.toString() ?? null);
        }

    }, [teams, selectablePokemonJson]);

    useEffect(() => {
        if (!selectedTeam || !token || !seasonId) {
			return;
        }

        getMatchHistoryRequest(seasonId, Number(selectedTeam), token, setMatchHistory);
    }, [selectedTeam, seasonId, token, setMatchHistory]);

    useEffect(() => {
        setLoadingMatchHistory(false);
    }, [matchHistory]);


    const teamSelectOptions = useMemo(() => getMenuItemsForTeams(teams), [teams]);
    const selectedTeamChanged = (event: SelectChangeEvent) => {
        setSelectedTeam(event.target.value);
    };

    return (
        <>
            {(!teams || teams.length === 0) && (
                <Box sx={{ m: 2, p: 2 }}>
                    Teams will show up here after the draft is over.
                </Box>
            )}
            {teams.length > 0 && (
                <Box sx={{ m: 2 }}>
                    <FormControl sx={{ minWidth: '200px', maxWidth: '500px' }}>
                        <InputLabel id="demo-simple-select-label">Team</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedTeam}
                            label="Team"
                            onChange={selectedTeamChanged}
                            native={isMobile} // Use native dropdown on mobile
                        >
                            {isMobile && teamSelectOptions.nativeOptions}
                            {!isMobile && teamSelectOptions.options}
                        </Select>
                    </FormControl>
                </Box>
            )}
            {seasonId && teams && teams.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    <PokemonTeamWrapper team={teams.find((t) => t.TeamId === Number(selectedTeam)) ?? teams[0]} isLeagueOwner={isLeagueOwner} seasonId={seasonId} showTier={showTier} showFilter={showFilter} />
                    {loadingMatchHistory && (
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!loadingMatchHistory && (
                        <Box sx={{ ml: 2, mr: 2 }}>
                            <MatchHistory teams={teams} matchHistory={matchHistory} leagueOwner={isLeagueOwner} />
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
}
import * as React from 'react';
import { useMemo, useState } from 'react';
import TeamWeaknessTable from './TeamWeaknessTable';
import TeamStatsTable from './TeamStatsTable';
import CssBaseline from '@mui/material/CssBaseline';
import { PokemonTeam } from '../types/PokemonTeam';
import TeamSpeedTable from './TeamSpeedTable';
import { Box, Button, Checkbox, ListItemText, Menu, MenuItem } from '@mui/material';
import { Pokemon } from '../types/Pokemon';
import EditTeam from './EditTeam';
import PokemonMoveCategories from './PokemonMoveCategories';

type Props = {
    team: PokemonTeam,
    isLeagueOwner: boolean,
    seasonId: string,
    showTier: boolean,
    showFilter: boolean,
}

export default function PokemonTeamWrapper({ team, isLeagueOwner, seasonId, showTier, showFilter }: Props) {
    const uniquePokemon = team?.Pokemon.filter((pokemon, index, self) =>
        index === self.findIndex((p) => (
            p.UniqueId === pokemon.UniqueId
        ))
    );

    const [pokemonFilter, setPokemonFilter] = useState<string[]>([]);
    const [pokemonToShow, setPokemonToShow] = useState<Pokemon[]>(team?.Pokemon ?? []);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    React.useEffect(() => {
        if (pokemonFilter.length > 0) {
            setPokemonToShow(team?.Pokemon.filter((pokemon) => pokemonFilter.includes(pokemon.UniqueId.toString())) ?? []);
        } else {
            setPokemonToShow(team?.Pokemon ?? []);
        }
    }, [pokemonFilter, team?.Pokemon]);

    React.useEffect(() => {
        setPokemonFilter(uniquePokemon ? uniquePokemon.map((pokemon) => pokemon.UniqueId.toString()) : []);
    }, [team]);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (id: string) => {
        setPokemonFilter((prevSelectedIds) =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter((i) => i !== id)
                : [...prevSelectedIds, id]
        );
    };

    const memoStats = useMemo(() => <TeamStatsTable pokemon={pokemonToShow} teamId={team?.TeamId} showTier={showTier} />, [pokemonToShow]);
    const memoWeakness = useMemo(() => <TeamWeaknessTable pokemon={pokemonToShow} teamId={team?.TeamId} />, [pokemonToShow]);
    const memoSpeed = useMemo(() => <TeamSpeedTable pokemon={pokemonToShow} teamId={team?.TeamId} />, [pokemonToShow])

    return (
        <>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', m: 2 }}>
                <Box>
                    {isLeagueOwner &&
                        <EditTeam team={team} seasonId={seasonId} />
                    }
                </Box>
                {showFilter && (
                    <Box>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            Filter
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {uniquePokemon.map((pokemon, index) => (
                                <MenuItem key={"filter" + index} value={pokemon.UniqueId.toString()} onClick={() => handleChange(pokemon.UniqueId.toString())}>
                                    <Checkbox checked={pokemonFilter.indexOf(pokemon.UniqueId.toString()) > -1} />
                                    <ListItemText primary={pokemon.Name} />
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                )}                
            </Box>
            {team?.Pokemon?.length > 0 && (
                <>
                    {memoStats}
                    {memoWeakness}
                    {memoSpeed}
                    <PokemonMoveCategories team={team} />
                </>
            )}
        </>
    );
}

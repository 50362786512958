import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, Container, CssBaseline, Link, Paper, Popover, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { GetMatchStatsResponse, PokemonBattleStats, ShowdownPlayer } from '../Stats/MatchStatsTable';
import PokemonImage from '../PokemonImage';
import DamageBarChart from './DamageBarChart';
import { Pokemon } from '../../types/Pokemon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'; // Add this for the winner and mvp?
import PokemonGif from './PokemonGif';
import HomeAppBar from '../PublicHome/HomeAppBar';

export interface PokemonData {
	Pokemon: Pokemon;
	DamageDealt: number;
	DamageHealed: number;
	DamageTaken: number;
	Kills: number;
	HazardDamageDealt: number;
	StatusDamageDealt: number;
	WeatherDamageDealt: number;
}

async function getReplayStats(request: any, setData: (r: GetMatchStatsResponse) => void, setLoading: (b: boolean) => void) {
	fetch('/PublicReplays/Replay/', {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json'
		}),
		body: JSON.stringify(request)
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			alert(message);
			return;
		}

		setData(message);
		setLoading(false);
	});
}

const DAMAGE_WEIGHT = 1;
const HEAL_WEIGHT = 1;
const TAKEN_WEIGHT = 1;
const KILL_WEIGHT = 50;
function getMvpRanking(pokemonData: PokemonData) {
	const totalDamageDealt = pokemonData.DamageDealt
		+ pokemonData.HazardDamageDealt
		+ pokemonData.StatusDamageDealt
		+ pokemonData.WeatherDamageDealt;

	const damageHealed = pokemonData.DamageHealed;
	const damageTaken = pokemonData.DamageTaken;
	const kills = pokemonData.Kills;

	const mvpRank = (totalDamageDealt * DAMAGE_WEIGHT)
		+ (damageHealed * HEAL_WEIGHT)
		+ (damageTaken * TAKEN_WEIGHT)
		+ (kills * KILL_WEIGHT);

	return mvpRank;
}

function getTankRanking(pokemonData: PokemonData) {
	const taken = pokemonData.DamageTaken * TAKEN_WEIGHT;
	const healing = pokemonData.DamageHealed * HEAL_WEIGHT;
	return taken + healing;
}

function getHelperRanking(pokemonData: PokemonData) {
	// Check healing, status damage, weather damage, and hazard damage
	const healing = pokemonData.DamageHealed * HEAL_WEIGHT;
	const status = pokemonData.StatusDamageDealt * DAMAGE_WEIGHT;
	const weather = pokemonData.WeatherDamageDealt * DAMAGE_WEIGHT;
	const hazard = pokemonData.HazardDamageDealt * DAMAGE_WEIGHT;
	return healing + status + weather + hazard;
}

const urlPattern = /^https:\/\/replay\.pokemonshowdown\.com\/.+$/;

function isValidReplayUrl(replayUrl: string | undefined): boolean {
	if (!replayUrl) {
		return false;
	}

	return urlPattern.test(replayUrl);
}

export default function PublicReplaysPage() {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [replayStats, setReplayStats] = React.useState<GetMatchStatsResponse | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const replayUrlRef = React.useRef<HTMLInputElement>(null);

	const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onSubmitReplay = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (replayUrlRef.current === null || !isValidReplayUrl(replayUrlRef.current.value)) {
			alert('Invalid Replay URL');
			return;
		}

		setLoading(true);
		const url = replayUrlRef.current.value;
		getReplayStats({
			ReplayUrl: url,
		}, setReplayStats, setLoading);
	};

	const mvpForTeam = (team: ShowdownPlayer) => {
		let mvp: PokemonBattleStats | null = null;
		let mvpRank = 0;
		team.Pokemon.forEach(pokemon => {
			const rank = getMvpRanking(pokemon);
			if (rank > mvpRank || mvp == null) {
				mvp = pokemon;
				mvpRank = rank;
			}
		});

		return mvp!;
	};

	const lvpForTeam = (team: ShowdownPlayer) => {
		let lvp: PokemonBattleStats | null = null;
		let lvpRank = 0;
		team.Pokemon.forEach(pokemon => {
			const rank = getMvpRanking(pokemon);
			if (rank < lvpRank || lvp == null) {
				lvp = pokemon;
				lvpRank = rank;
			}
		});

		return lvp!;
	};

	const punchingBagForTeam = (team: ShowdownPlayer) => {
		let punchingBag: PokemonBattleStats | null = null;
		let punchingBagRank = 0;
		team.Pokemon.forEach(pokemon => {
			const rank = getTankRanking(pokemon);
			if (rank > punchingBagRank || punchingBag == null) {
				punchingBag = pokemon;
				punchingBagRank = rank;
			}
		});

		return punchingBag!;
	};

	const helperForTeam = (team: ShowdownPlayer) => {
		let helper: PokemonBattleStats | null = null;
		let helperRank = 0;
		team.Pokemon.forEach(pokemon => {
			const rank = getHelperRanking(pokemon);
			if (rank > helperRank || helper == null) {
				helper = pokemon;
				helperRank = rank;
			}
		});

		return helper!;
	};

    return (
		<Box>
			<CssBaseline />
			<HomeAppBar />
			<Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				<Box component="form" onSubmit={onSubmitReplay} sx={{ width: "100%" }}>
					<Paper elevation={4} sx={{ p: 2, mt: 2 }}>
						<Typography variant="body1" color="neutral">
							Paste the link from a pokemon showdown replay to get stats from the match.
						</Typography>
						<Link
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup="true"
							onMouseEnter={handlePopoverOpen}
							onMouseLeave={handlePopoverClose}
							underline="hover"
							sx={{ cursor: 'pointer' }}
							variant="body2"
						>
							Known issues
						</Link>
						<Popover
							id="mouse-over-popover"
							sx={{ pointerEvents: 'none' }}
							open={open}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
							onClose={handlePopoverClose}
							disableRestoreFocus
						>
							<Typography sx={{ p: 1 }}>
								<ul>
									<li>
										<Typography variant="body2" color="neutral">
											Any match with Zoroark or Zorua will not have correct stats.
										</Typography>
									</li>
									<li>
										<Typography variant="body2" color="neutral">
											Some healing moves are not being accounted for ("Slack off", "Pain split", maybe more?).
										</Typography>
									</li>
									<li>
										<Typography variant="body2" color="neutral">
											"Future sight" damage and kills not accounted for.
										</Typography>
									</li>
								</ul>
							</Typography>
						</Popover>						
						<Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
							<TextField
								variant="outlined"
								margin="none"
								required
								fullWidth
								id="replayUrl"
								label="Replay URL"
								name="replayUrl"
								autoFocus
								inputRef={replayUrlRef}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								Submit
							</Button>
						</Box>
					</Paper>
				</Box>
				{loading && (
					<Box sx={{ position: "fixed", top: "20%", left: "50%" }}>
						<CircularProgress />
					</Box>
				)}
				{!!replayStats && (
					<>
						{replayStats.Stats.map((team, teamIndex) => (
							<Paper elevation={4} sx={{ p: 2, m: 2, width: "100%" }}>							
								<Box key={"expanded-stats-" + teamIndex}>
									<Box style={{ display: 'flex', justifyContent: 'center' }}>
										{replayStats.Winner === team.Username && (
											<EmojiEventsIcon sx={{ mr: 1, color: 'gold' }} />
										)}
										<Typography variant="h6">
											{team.Username}
										</Typography>
										{replayStats.Winner === team.Username && (
											<EmojiEventsIcon sx={{ ml: 1, color: 'gold' }} />
										)}
									</Box>					
									<Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-evenly', mb: 1 }}>
										<Paper elevation={5} sx={{ p: 1, m: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Typography variant="body1" gutterBottom>
												MVP
											</Typography>
											<Box sx={{ height: "100%" }}>
												<PokemonGif pokemon={mvpForTeam(team)}/>
											</Box>
										</Paper>
										<Paper elevation={5} sx={{ p: 1, m: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Typography variant="body1" gutterBottom>
												Punching Bag
											</Typography>
											<Box sx={{ height: "100%" }}>
												<PokemonGif pokemon={punchingBagForTeam(team)}/>
											</Box>
										</Paper>
										<Paper elevation={8} sx={{ p: 1, m: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Typography variant="body1" gutterBottom>
												The Helper
											</Typography>
											<Box sx={{ height: "100%" }}>
												<PokemonGif pokemon={helperForTeam(team)}/>
											</Box>
										</Paper>
										<Paper elevation={8} sx={{ p: 1, m: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
											<Typography variant="body1" gutterBottom>
												Trying his best
											</Typography>
											<Box sx={{ height: "100%" }}>
												<PokemonGif pokemon={lvpForTeam(team)}/>
											</Box>
										</Paper>
									</Box>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell padding="checkbox">Pokemon</TableCell>
												<TableCell padding="checkbox">Live</TableCell>
												<TableCell padding="checkbox">Kills</TableCell>
												<TableCell padding="checkbox">Dmg</TableCell>
												<TableCell padding="checkbox">Dmg Taken</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{team.Pokemon.map((pokemon, pokemonIndex) => (
												<TableRow key={pokemonIndex}>
													<TableCell>
														{pokemon.Pokemon && (
															<Box>
																<PokemonImage pokemon={pokemon.Pokemon} />
																{pokemon.Pokemon.Name}
															</Box>
														)}
													</TableCell>
													<TableCell>
														{pokemon.Fainted ? <HighlightOffIcon color="error" /> : <CheckCircleIcon color="success" />}
													</TableCell>
													<TableCell>{pokemon.Kills}</TableCell>
													<TableCell>{pokemon.DamageDealt + pokemon.HazardDamageDealt + pokemon.StatusDamageDealt + pokemon.WeatherDamageDealt}</TableCell>
													<TableCell>{pokemon.DamageTaken}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
									<DamageBarChart teams={[team]} />
								</Box>
							</Paper>
						))}
					</>					
				)}
			</Container>			
		</Box>
    );
}


import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { formatMatchDate, Schedule } from './Matchups';
import { getActiveTeamName } from './ScheduleCreator';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Team } from '../../../../types/Team';

interface UpcomingMatchesProps {
	schedule: Schedule[],
	teams: Team[]
}

export default function UpcomingMatches({ schedule, teams }: UpcomingMatchesProps) {
	// Get the schedule matches for the next 7 days, including today.
	const today = new Date();
	const nextWeek = new Date(today);
	nextWeek.setDate(nextWeek.getDate() + 7);
	const upcomingMatches = schedule.filter((match: Schedule) => {
		if (!match.ScheduledTime) {
			return false;
		}
		const matchDate = new Date(match.ScheduledTime);
		return matchDate >= today && matchDate <= nextWeek;
	});

	// Sort the matches by date
	upcomingMatches.sort((a: Schedule, b: Schedule) => {
		if (!a.ScheduledTime || !b.ScheduledTime) {
			return 0;
		}
		const dateA = new Date(a.ScheduledTime);
		const dateB = new Date(b.ScheduledTime);
		return dateA.getTime() - dateB.getTime();
	});

	const getMatchName = (match: Schedule) => {
		const team1 = teams.find((team: Team) => team.Id === match.TeamOneId);
		const team2 = teams.find((team: Team) => team.Id === match.TeamTwoId);
		return `${getActiveTeamName(team1)} vs ${getActiveTeamName(team2)}`;
	};

	// Don't render anything if there are no upcoming matches
	if (upcomingMatches.length === 0) {
		return null;
	}

	return (
		<Paper elevation={4} sx={{ p: 2, mt: 2, mb: 2, overflow: 'hidden', maxWidth: '800px', minWidth: '400px' }}>		
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Typography component="h2" variant="h6" gutterBottom sx={{ marginBottom: 0 }}>
					Upcoming Matches
				</Typography>
				<Tooltip
					title="Next 7 days, including today."
					placement="right"
					arrow
				>
					<InfoOutlinedIcon fontSize="small" sx={{ marginLeft: 0.5 }} />
				</Tooltip>
			</Box>			
			<TableContainer component={Paper} sx={{ marginTop: 1 }}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Matchup</TableCell>
							<TableCell align="right">Time</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{upcomingMatches.map((match) => (
							<TableRow
								key={match.Id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{getMatchName(match)}
								</TableCell>
								<TableCell align="right">{formatMatchDate(match.ScheduledTime)}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>		
	);
}
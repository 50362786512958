import * as React from 'react';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';
import { useHistory } from "react-router-dom";
import useToken from "../../useToken";

export default function HomeAppBar() {
	const history = useHistory();
	const { token } = useToken();

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography variant="h6" component="div">
						<Button color="inherit" onClick={() => history.push('/')}>Draft League</Button>
					</Typography>
					<Box sx={{ ml: 2 }}>
						<Typography sx={{ textAlign: 'center' }}>
							<Button color="inherit" onClick={() => history.push('/replay')}>Replays</Button>
						</Typography>
					</Box>
					<Box sx={{ marginLeft: 'auto' }}>
						{!token && (
							<Typography sx={{ textAlign: 'center' }}>
								<Button color="inherit" onClick={() => history.push('/home')}>Login</Button>
							</Typography>
						)}	
					</Box>									
				</Toolbar>
			</AppBar>
		</Box>
	);
}
import * as React from 'react';
import useToken from '../useToken';
import Login from '../components/Login'
import { useParams } from 'react-router-dom';
import { Token } from '../types/Token';

interface InviteRequest {
    Id: string,
}

async function tryInvite(invite: InviteRequest, token: Token, history: any) {
    fetch('/invite', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(invite)
    }).then(async response => {

        const message = await response.json();
        if (!response.ok) {
            alert("Invite link is invalid");
            history.push("/");
        }

        const invitedLeagueId = message.LeagueId;
        if (invitedLeagueId) {
            history.push("/home/" + invitedLeagueId);
        }
        else {
            history.push("/");
        }
    });
}

interface InviteParams {
    id: string,
}

export default function Invite({ history }: any) {
    const { token, setToken } = useToken();
    let { id } = useParams<InviteParams>();

    const submitInvite = async () => {
        await tryInvite({
            Id: id
        }, token, history);
        
    }

    if (!id || id.length === 0) {
        return (<div>Invalid invite id</div>);
    }

    if (!token) {
        return <Login setToken={setToken} />
    }

    submitInvite();

    return (<div></div>);
}


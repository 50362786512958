import * as React from 'react';
import Box from '@mui/material/Box';
import { PokemonTeam } from '../../../types/PokemonTeam';
import { CircularProgress, Paper } from '@mui/material';
import { Token } from '../../../types/Token';
import useToken from '../../../useToken';
import { useEffect, useState } from 'react';
import Standings from './components/Standings';
import MatchHistory from './components/MatchHistory';
import { HistoricalMatch } from '../../../types/HistoricalMatch';
import Matchups from './components/Matchups';
import { Team } from '../../../types/Team';

async function getMatchHistoryRequest(seasonId: string, token: Token, setMatchHistory: any) {
	fetch('/Match/History/' + seasonId, {
		method: 'GET',
		headers: new Headers({
			'Authorization': 'Bearer ' + token
		})
	}).then(async response => {
		const message = await response.json();

		if (!response.ok) {
			return;
		}

		setMatchHistory(message);
	});
}

interface LeagueTabProps {
	teams: PokemonTeam[],
	activeTeams: Team[],
	leagueId: string,
	seasonId: string,
	leagueOwner: boolean,
	userTeamId: number
}

export default function LeagueTab({ teams, activeTeams, leagueId, seasonId, leagueOwner, userTeamId }: LeagueTabProps) {
	const [matchHistory, setMatchHistory] = useState<HistoricalMatch[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const { token } = useToken();

	useEffect(() => {
		if (loading) {
			getMatchHistoryRequest(seasonId, token, setMatchHistory);
		}		
	}, [seasonId, token, loading]);

	useEffect(() => {
		setLoading(false);
	}, [matchHistory]);

	const reloadMatchHistory = () => {
		setLoading(true);
	}

	return (
		<>
			{(!teams || teams.length === 0) && (
				<>
					<Box sx={{ m: 2, p: 2 }}>
						League standings can be viewed here after the draft is over. The schedule can be created now but won't be visible to the players until the draft is over
					</Box>
					{leagueOwner && (
						<Matchups leagueId={leagueId} seasonId={seasonId} leagueOwner={leagueOwner} activeTeams={activeTeams} historicalMatches={matchHistory} userTeamId={userTeamId} reloadMatchHistory={reloadMatchHistory} draftComplete={false} />
					)}
				</>
			)}
			{teams.length > 0 && (
				<Box>
					<Box>
						{loading && (
							<Box sx={{ display: 'flex' }}>
								<CircularProgress />
							</Box>
						)}
						{!loading && (
							<Box>
								<Matchups leagueId={leagueId} seasonId={seasonId} leagueOwner={leagueOwner} activeTeams={activeTeams} historicalMatches={matchHistory} userTeamId={userTeamId} reloadMatchHistory={reloadMatchHistory} draftComplete={true} />
								<Standings teams={teams} matchHistory={matchHistory} />
								<MatchHistory teams={teams} matchHistory={matchHistory} leagueOwner={leagueOwner} />
							</Box>
						)}
					</Box>
				</Box>
			)}
		</>
	);
}